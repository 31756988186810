// Planta Application has two ways of running and separate configuration for each of them.
// 1. Standalone - run in a Docker container, reading configuration from environment variables that can
//                 be changed without a need of rebuilding the application: `window.__RUNTIME_CONFIG__`
// 2. Opp Parcel - run as an component within OPP application, reading hardcoded configuration from `OPP_PARCEL_CONFIG`
// TODO: when PLANTA as Standalone application will be deprecated adjust config to Parcel only

declare global {
  interface Window {
    readonly __RUNTIME_CONFIG__: ImportMetaEnv;
  }
}

const sharedConfig = {
  SENTRY_RELEASE: "",
  SENTRY_DSN: "",
  VITE_TRACKER_URL: "",
  BASE_URL: "",
  MODE: "",
  DEV: false,
  PROD: false,
  SSR: false,
  WELCOME_HELP_URL: "",
  VITE_WELCOME_HELP_URL:
    "https://iweof.sharepoint.com/:b:/r/sites/ms/rwm/hr/globalpeopleplanninghub/Shared%20Documents/Workload%20planning/1.%20User%20manual/Upload%20File%20Instruction/Workload_Planning_Upload-File_Instruction.pdf?csf=1&web=1&e=9V2HwX",
  ADMIN_GROUP_IDS:
    "2e894baa-ba93-438c-8dd3-76041dbdaaf9,2f9847ea-654e-4d38-b29d-8951ba615e45",
  FORECASTING_DASHBOARD_URL: "",
  VITE_FORECASTING_DASHBOARD_URL:
    "https://app.powerbi.com/groups/1cba664d-e5a2-4a48-ade4-a9f60605280b/reports/0cd3e21a-bf41-4438-8ee0-f1871bf7eedd?ctid=720b637a-655a-40cf-816a-f22f40755c2c&pbi_source=linkShare",
  EXAMPLE_CSV_URL:
    "https://storage.googleapis.com/opp-example-download-csv/example.csv",
};

const OPP_PARCEL_CONFIG: OppParcelConfig = {
  dev: {
    VITE_APP_TENANT_ID: "720b637a-655a-40cf-816a-f22f40755c2c",
    VITE_APP_CLIENT_ID: "4b81ac6b-942c-4452-a0d6-b6fa37df66af",
    VITE_API_URL: "https://planta-dev.ingkadt.com/api",
    ENVIRONMENT: "dev-v1",
    OPP_AUTHZ: "true",
    ...sharedConfig,
  },
  stage: {
    VITE_APP_TENANT_ID: "720b637a-655a-40cf-816a-f22f40755c2c",
    VITE_APP_CLIENT_ID: "ca37156f-5b68-4d45-a93b-a4f2a66faa19",
    VITE_API_URL: "https://planta-stage.ingkadt.com/api",
    ENVIRONMENT: "stage-v1",
    OPP_AUTHZ: "false",
    ...sharedConfig,
  },
  demo: {
    VITE_APP_TENANT_ID: "720b637a-655a-40cf-816a-f22f40755c2c",
    VITE_APP_CLIENT_ID: "ca37156f-5b68-4d45-a93b-a4f2a66faa19",
    VITE_API_URL:
      "https://pdpp-planta-x-planta-server-demo-v1-esd3r3zcra-ez.a.run.app",
    ENVIRONMENT: "demo-v1",
    OPP_AUTHZ: "false",
    ...sharedConfig,
  },
  prod: {
    VITE_APP_TENANT_ID: "720b637a-655a-40cf-816a-f22f40755c2c",
    VITE_APP_CLIENT_ID: "99ec0cb6-0308-47a1-9d46-6db0e11bee9e",
    VITE_API_URL: "https://planta.ingka.com/api",
    ENVIRONMENT: "prod-v1",
    OPP_AUTHZ: "false",
    ...sharedConfig,
  },
};

const prodOPP = "peopleplanning.ingka.com";
const h = window.location.hostname;
const OPP_ENV = h.includes("stage")
  ? "stage"
  : h.includes("demo")
    ? "demo"
    : h === prodOPP
      ? "prod"
      : "stage";

console.info(`Running Workload Parcel in ${OPP_ENV} environment`);

function get(key: string): string {
  const c = window.__RUNTIME_CONFIG__ ?? OPP_PARCEL_CONFIG[OPP_ENV];
  return c[key];
}

export const ENVIRONMENT = get("ENVIRONMENT");
export const SENTRY_RELEASE = get("SENTRY_RELEASE");
export const SENTRY_DSN = get("SENTRY_DSN");
export const VITE_APP_CLIENT_ID = get("VITE_APP_CLIENT_ID");
export const VITE_APP_TENANT_ID = get("VITE_APP_TENANT_ID");
export const VITE_API_URL = get("VITE_API_URL");
export const VITE_TRACKER_URL = get("VITE_TRACKER_URL");
export const WELCOME_HELP_URL = get("WELCOME_HELP_URL");
export const ADMIN_GROUP_IDS = get("ADMIN_GROUP_IDS");
export const FORECASTING_DASHBOARD_URL = get("FORECASTING_DASHBOARD_URL");
export const EXAMPLE_CSV_URL = get("EXAMPLE_CSV_URL");
export const OPP_AUTHZ = get("OPP_AUTHZ") === "true";
