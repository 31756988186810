/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
export const deepFilter = <T>(
  collection: T[],
  searchKey: string,
  searchFields: string[],
): T[] => {
  if (!collection?.length || searchKey.trim().length === 0) {
    return [];
  }

  const filterResults = <T>(
    collection: T[],
    searchKey: string,
    searchFields: string[],
  ): T[] => {
    const res: T[] = [];
    for (const item of collection) {
      for (const field of searchFields) {
        if (deepMatch(item, searchKey, field)) {
          res.push(item);
          break;
        }
      }
    }
    return res;
  };
  const deepMatch = (obj: any, searchKey: string, field: any): T => {
    const [current, ...next] = Array.isArray(field) ? field : field.split(".");
    if (next.length > 0 || (Array.isArray(next) && next?.length > 0)) {
      return deepMatch(obj[current], searchKey, next);
    }
    return iterate(obj, current, searchKey);
  };

  const iterate = (obj: any, searchField: string, searchKey: string) => {
    if (
      !Array.isArray(obj) &&
      typeof obj[searchField] !== "object" &&
      `${obj[searchField]}`.toLowerCase().includes(searchKey.toLowerCase())
    ) {
      return obj;
    }

    if (!Array.isArray(obj) && typeof obj[searchField] === "object") {
      iterate(obj[searchField], searchField, searchKey);
    }

    if (Array.isArray(obj)) {
      return obj.find((entry) =>
        `${entry[searchField]}`.toLowerCase().includes(searchKey.toLowerCase()),
      );
    }
  };

  return filterResults(collection, searchKey, searchFields);
};

export const naturalSort = <T>(list: T[], key: keyof T) => {
  const copy = [...list];
  return copy.sort((a: T, b: T) => {
    if (typeof a[key] !== "string") {
      return 0;
    }
    const aString = a[key] as string;
    const bString = b[key] as string;
    if (!aString || !bString) {
      return 0;
    }

    return aString.localeCompare(bString, undefined, { numeric: true });
  });
};

export const capitalize = (str: string) => {
  return str.charAt(0).toLocaleUpperCase() + str.substring(1);
};

// SHOULD BE REMOVED
export const generateHtmlClass = (...args: any): string => {
  return args
    .map((v: any) => {
      if (v === null || v === undefined || v === false) {
        return "";
      }

      if (typeof v === "string") {
        return v.trim();
      }

      if (v instanceof Array) {
        return generateHtmlClass(...v);
      }

      if (typeof v === "object") {
        const validClasses = Object.keys(v).filter((name) => !!v[name]);
        return validClasses.length ? generateHtmlClass(validClasses) : "";
      }

      return v.toString();
    })
    .filter((v: string) => v !== "")
    .join(" ");
};
