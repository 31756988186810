import { FC, Suspense, lazy, useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useTranslation, Trans } from "react-i18next";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";

import Hyperlink from "@ingka/hyperlink";
import Text from "@ingka/text";
import IngkaLoading, { LoadingBall } from "@ingka/loading";
import {
  ErrorBoundary,
  ErrorPage,
  ErrorDisplay,
  getLocationWithQueryParams,
} from "@pdpp/lib-react";
import arrowCloudIn from "@ingka/ssr-icon/paths/arrow-cloud-in";
import Button from "@ingka/button";
import { useAppVersion } from "@pdpp/lib-react";
import { OPPPermissions, OPPRoles } from "@pdpp/lib-planta";

import { useBaseNavigate } from "./hooks/useBaseNavigate";
import { css } from "./__generated-styled-system/css";
import { ROUTES } from "./config";
import { NOW_IT_LINK } from "./config/help";
import { AppLayout, NavItemWithClick } from "./cwds/AppLayout";
import { useGetOrgStructure } from "./features/org/api";
import { OrganisationSelect } from "./components/OrganisationSelect";
import { PlanPeriod } from "./plan/PlanPeriod";
import { Period } from "./plan/Period";
import { useAnalytics } from "./hooks/useAnalytics";
import { Auth } from "./api/auth";
import { MSAL_SCOPES } from "./api/fetch";
import { orgId$, orgNodes$ } from "./features/org/state";
import { ADMIN_GROUP_IDS, OPP_AUTHZ } from "./utils/runtimeConfig";
import {
  CODE_BY_COUNTRY_NAME,
  getUnitFromOfficeLocation,
} from "./features/org/transforms";
import { PlanBudget } from "./plan/PlanBudget";
import { showV6 } from "./features/experimental/transforms";
import { useGetUserPermissions } from "./features/opp/useGetUserPermissions";
import { hasOppPermissions } from "./utils";
import { UnitSelect } from "./components/UnitSelect";
import { ModalStack } from "./countryActivities/common/ModalStack";
import { userPermissions$ } from "./features/opp/state";

const Main = lazy(() => import("./plan/Main"));
const Publish = lazy(() => import("./publications/Publish"));
const CountryActivities = lazy(
  () => import("./countryActivities/ActivitiesWrapper"),
);

const containerStyles = css({
  display: "grid",
  height: "100vh",
  width: "100vw",
  position: "relative",
  backgroundColor: "colourNeutral2",
});

const headerStyles = css({
  display: "grid",
  gridTemplateColumns: "1fr max-content max-content max-content max-content",
  justifyItems: "end",
  gap: "space300!",
  _first: { justifyItems: "start" },
  "@media (width < 760px)": {
    gridTemplateColumns: "auto auto 1fr",
    gap: "space200!",
  },
});

const buttonStyles = css({
  alignItems: "center",
});

const loadingStyles = css({
  height: "100vh",
  width: "100vw",
});

const contentStyles = css({
  display: "grid",
  gridTemplateRows: "100px 1fr",
  height: "100vh",
});

const wrapperStyles = css({
  display: "grid",
  gridTemplateRows: "min-content min-content min-content",
  gridGap: "space50",
  paddingRight: "space550!",
  paddingLeft: "space550!",
  paddingTop: "space150!",
  height: "100%",
  "@media (width < 780px)": {
    paddingRight: "space250!",
    paddingLeft: "space250!",
  },
});

const Loading: FC = () => {
  const [t] = useTranslation();

  return (
    <IngkaLoading
      className={loadingStyles}
      text={t("labels.loading")}
      labelTransitions={true}
    >
      <LoadingBall />
    </IngkaLoading>
  );
};

export const App: FC = () => {
  const { accounts, inProgress } = useMsal();
  const location = useLocation();
  const { navigate, getBaseLocationQueryParams } = useBaseNavigate();
  const [t] = useTranslation();
  const [userCountry, setUserCountry] = useState<string | "ALL_COUNTRIES">("");
  const { isError: isOppError, isLoading: isOppLoading } =
    useGetUserPermissions();
  const org = useGetOrgStructure(
    accounts.length > 0 && inProgress === InteractionStatus.None
      ? AbortSignal.abort()
      : undefined,
  );

  const orgNode = orgId$.value;
  const orgNodes = orgNodes$.value;
  // TODO: if `org` was a signal, this could be a computed signal too:
  const orgLevel = org.data?.nodes.find((item) => item.id === orgNode)?.level;
  const units = orgNodes.filter((n) => n.type === "unit");
  const currentVersion = import.meta.env["VERSION"];
  useAppVersion({ currentVersion, callbackFn: () => (orgId$.value = "") });

  useEffect(() => {
    const paths = Object.keys(ROUTES) as Array<keyof typeof ROUTES>;
    if (paths.find((p) => location.pathname.includes(ROUTES[p].path)) != null) {
      localStorage.setItem("path", location.pathname + location.search);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (accounts.length === 0 && inProgress === InteractionStatus.None) {
      Auth.get().login({ scopes: MSAL_SCOPES }).catch(console.error);
    }
  }, [accounts.length, inProgress]);

  useEffect(() => {
    const handleUserSelection = async (): Promise<void> => {
      const [user, userGroupIds] = await Promise.all([
        Auth.get().getUser(),
        Auth.get().getUserGroups("IAM"),
      ]);

      if (
        user === undefined ||
        user.profile.country === undefined ||
        user.profile.officeLocation === undefined
      ) {
        return;
      }

      const userUnit = getUnitFromOfficeLocation(user.profile.officeLocation);
      const noUnitSelected = orgId$.value === "" || orgId$.value === undefined;

      const isAdmin =
        userGroupIds.some((element) =>
          ADMIN_GROUP_IDS.split(",").includes(element),
        ) ||
        userPermissions$?.value?.roles?.some(
          (role) => role === OPPRoles.OPP_DEVELOPER,
        );

      if (isAdmin === false) {
        setUserCountry(CODE_BY_COUNTRY_NAME.get(user.profile.country) ?? "");
      } else {
        setUserCountry("ALL_COUNTRIES");
      }

      if (userUnit !== undefined) {
        const selected = units?.find((unit) => {
          return (
            unit.publicId === userUnit.code &&
            // @ts-expect-error - there is always `attributes` on unit because it's filtered by type
            unit?.attributes["businessUnitType"] === userUnit.type
          );
        });
        if (noUnitSelected && selected !== undefined) {
          orgId$.value = selected.id;
        }
      }
    };
    if (units.length > 0 && userCountry === "") {
      handleUserSelection();
    }
  }, [units, userCountry]);

  useAnalytics();

  const navItems = useMemo<Array<NavItemWithClick>>(
    () => [
      {
        label: t("plan.label"),
        href: ROUTES.PLAN.OVERVIEW.path,
        isActive: location.pathname.startsWith(ROUTES.PLAN.OVERVIEW.path),
        onClick: () => {
          navigate(getLocationWithQueryParams(ROUTES.PLAN.OVERVIEW.path));
        },
      },
      {
        label: t("activitiesTab.name"),
        href: ROUTES.COUNTRYACTIVITIES.path,
        isActive: location.pathname.startsWith(ROUTES.COUNTRYACTIVITIES.path),
        onClick: () => {
          navigate(getLocationWithQueryParams(ROUTES.COUNTRYACTIVITIES.path));
        },
      },
    ],

    [location.pathname, navigate, t],
  );

  if (
    !isOppError &&
    (inProgress !== InteractionStatus.None || org.loading || userCountry === "")
  ) {
    return (
      <div className={containerStyles}>
        <Loading />
      </div>
    );
  }
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const isCountryActivities =
    location.pathname === ROUTES.COUNTRYACTIVITIES.path;

  return (
    <div className={containerStyles}>
      {OPP_AUTHZ && isOppError && !isOppLoading ? (
        <ErrorPage
          title={t("errorPage.missingAccountTitle")}
          message={t("errorPage.missingAccountMessage")}
          videoUrl="/images/error-video.mp4"
          content={
            <Text tagName="span" bodySize="m">
              <Trans
                i18nKey="errorPage.nowItMessage"
                components={{
                  itlink: (
                    <Hyperlink
                      newWindow={true}
                      rel="noopener noreferrer nofollow"
                      url={NOW_IT_LINK}
                    />
                  ),
                }}
              />
            </Text>
          }
        />
      ) : (
        <Suspense fallback={<Loading />}>
          <div className={contentStyles}>
            <ModalStack>
              <AppLayout navItems={navItems}>
                {orgNode === undefined ||
                orgLevel === undefined ||
                orgLevel > 7 ? (
                  <UnitSelect units={units} userCountry={userCountry} />
                ) : (
                  <div className={wrapperStyles}>
                    {!isCountryActivities && (
                      <div className={headerStyles}>
                        <OrganisationSelect nodes={orgNodes} orgId={orgNode} />
                        {hasOppPermissions(
                          OPPPermissions.WORKLOAD_HEADCOUNT_READ,
                        ) && <PlanPeriod />}
                        {showV6 &&
                          hasOppPermissions(
                            OPPPermissions.WORKLOAD_BUDGET_READ,
                          ) && <PlanBudget />}
                        <Period />
                        {hasOppPermissions(
                          OPPPermissions.WORKLOAD_HEADCOUNT_WRITE,
                        ) && (
                          <Button
                            className={buttonStyles}
                            onClick={() => {
                              navigate(
                                getLocationWithQueryParams(ROUTES.PUBLISH.path),
                              );
                            }}
                            type="primary"
                            text={t("publications.publish")}
                            ssrIcon={arrowCloudIn}
                            small={true}
                          />
                        )}
                      </div>
                    )}

                    <ErrorBoundary
                      fallback={ErrorDisplay}
                      videoUrl="/images/error-video.mp4"
                      helpdeskUrl={NOW_IT_LINK}
                    >
                      <SentryRoutes>
                        <Route
                          path={ROUTES.PUBLISH.path}
                          element={<Publish />}
                        />
                        <Route path={ROUTES.PLAN.all} element={<Main />} />
                        <Route
                          path={ROUTES.COUNTRYACTIVITIES.path}
                          element={<CountryActivities />}
                        />
                        <Route
                          path="*"
                          element={
                            <Navigate
                              to={getBaseLocationQueryParams(
                                ROUTES.PLAN.OVERVIEW.path,
                              )}
                              replace
                            />
                          }
                        />
                      </SentryRoutes>
                    </ErrorBoundary>
                  </div>
                )}
              </AppLayout>
            </ModalStack>
          </div>
        </Suspense>
      )}
    </div>
  );
};
