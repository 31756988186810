/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  useMemo,
  useState,
  useContext,
  ReactNode,
  createContext,
  SVGProps,
  FC,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sheets,
  Prompt,
  Theatre,
} from "@ingka/modal";
import SSRIcon from "@ingka/ssr-icon";
import crossCircleIcon from "@ingka/ssr-icon/paths/cross-circle";
import questionMarkIcon from "@ingka/ssr-icon/paths/question-mark-circle";
import informationIcon from "@ingka/ssr-icon/paths/information-circle";
import Toast from "@ingka/toast";
import Button from "@ingka/button";
import { ApiErrorType } from "@pdpp/lib-planta";

import { generateHtmlClass } from "./utils";

// @todo: wait for transition to end instead of waiting an arbitrary time amount
const TRANSITION_TIME = 200;

enum PromptType {
  ERROR = -1,
  CONFIRM = 0,
  INFO = 1,
}

const getIcon = (type: PromptType) => {
  const map: Record<string, [string, () => SVGProps<SVGElement>[]]> = {
    [PromptType.ERROR]: ["#e00751", crossCircleIcon],
    [PromptType.CONFIRM]: ["#f26a2f", questionMarkIcon],
    [PromptType.INFO]: ["#f26a2f", informationIcon],
  };

  //@ts-expect-error - correct mapping
  const [color, icon] = map[type];
  return <SSRIcon color={color} paths={icon} />;
};

// use timeouts to make sure we don't close the prompt while another one is being set
let promptDismissTimeout: ReturnType<typeof setTimeout>;

type ModalOptions = {
  onClose?: () => void;
  onBack?: () => void;
  size?: "small" | "large" | "medium";
  unstyled?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
};

type ModalProps = {
  component: FC<any>;
  props: any;
  options: ModalOptions;
  visible: boolean;
  type: FC<ModalOptions & { visible: boolean; children: ReactNode }>;
};

type PromptProps = {
  summary?: ReactNode;
  title?: ReactNode;
  onClose?: () => void;
  onAccept?: () => void;
  error?: string | ApiErrorType[];
  visible?: boolean;
};

interface ModalStackValue {
  /** open a new sheet modal and add it to the stack */
  openSheet: <T>(component: FC<T>, args?: (T & ModalOptions) | string) => void;

  /** open a theatre modal */
  openTheatre: <T>(
    component: FC<T>,
    args?: (T & ModalOptions) | string,
  ) => void;

  /** close last 'amount' of sheet/theatre modals; defaults to 1 */
  closeModal: (amount?: number) => void;

  /** close all sheet/theatre modals */
  closeAllModals: () => void;

  /** sheet/theatre modal stack */
  modalStack: ModalProps[];

  /** toast, there can be only one */
  toast?: string;

  /** set current toast message */
  setToast: (text: string) => void;

  /** prompt modal - no stack, there can be only one */
  prompt: false | PromptProps;

  /** open or close prompt alert ('false' to close) */
  setPrompt: (newPrompt: PromptProps | string | false) => void;
}

const ModalStackContext = createContext<ModalStackValue>({} as any);

const TheatreModal = (
  props: ModalOptions & { children: ReactNode; visible: boolean },
) => {
  const { closeModal, modalStack, closeAllModals } =
    useContext(ModalStackContext);
  const {
    onClose = closeModal,
    onBack,
    visible = true,
    header,
    footer,
    children,
    unstyled,
  } = props;

  const closeHandler = () => {
    closeAllModals();
    if (onClose) {
      onClose();
    }
  };

  const backHandler = () => {
    closeModal();
    if (onBack) {
      onBack();
    }
  };

  const headerNode =
    typeof header === "string" ? (
      <ModalHeader
        title={header}
        backBtnClick={modalStack.length > 1 ? backHandler : undefined}
        closeBtnClick={() => onClose()}
      />
    ) : (
      header
    );

  return (
    <Modal
      className="content-box"
      visible={visible}
      handleCloseBtn={closeHandler}
    >
      <Theatre header={<></>}>
        {unstyled ? (
          children
        ) : (
          <div className="container">
            <div className="header">{headerNode}</div>
            <div className="scrollable-content">{children}</div>
            {footer && <div className="footer">{footer}</div>}
          </div>
        )}
      </Theatre>
    </Modal>
  );
};

const PromptModal = (props: PromptProps) => {
  const { t } = useTranslation();
  const { setPrompt } = useContext(ModalStackContext);
  const { visible = true, title, onAccept, onClose, error, summary } = props;
  const promptType = error
    ? PromptType.ERROR
    : onAccept
      ? PromptType.CONFIRM
      : PromptType.INFO;

  const closeAction = () => {
    if (onClose) {
      onClose();
    }

    setPrompt(false);
  };

  const acceptAction = () => {
    if (onAccept) {
      onAccept();
      setPrompt(false);
    } else {
      closeAction();
    }
  };

  return (
    <Modal visible={visible} escapable={false} handleCloseBtn={closeAction}>
      <Prompt
        title=""
        titleId="modal-stack"
        className={generateHtmlClass(
          "popup-alert",
          promptType === PromptType.CONFIRM ? "cautionary" : "negative",
        )}
        header={null}
        footer={
          <ModalFooter>
            {onAccept && (
              <Button
                text={t("action.cancel")}
                onClick={closeAction}
                type="secondary"
                size="small"
                className="mt-3"
              />
            )}
            <Button
              text={t("action.ok")}
              onClick={acceptAction}
              type="primary"
              size="small"
              className="mt-3"
            />
          </ModalFooter>
        }
      >
        <div className="message">
          <div className="icon">{getIcon(promptType)}</div>
          <div className="text">
            <h3>{title}</h3>

            {summary && summary}
          </div>
        </div>
      </Prompt>
    </Modal>
  );
};

const SheetModal = (
  props: ModalOptions & { children: ReactNode; visible: boolean },
) => {
  const {
    onClose,
    onBack,
    unstyled = true,
    size,
    header,
    footer,
    visible,
    children,
  } = props;
  const { closeModal, closeAllModals, modalStack } =
    useContext(ModalStackContext);

  const closeHandler = () => {
    closeAllModals();
    if (onClose) {
      onClose();
    }
  };

  const backHandler = () => {
    closeModal();
    if (onBack) {
      onBack();
    }
  };

  const headerNode =
    typeof header === "string" ? (
      <ModalHeader
        title={header}
        backBtnClick={modalStack.length > 1 ? backHandler : undefined}
      />
    ) : null;

  return (
    <Modal
      className="sheet-modal"
      visible={visible}
      handleCloseBtn={closeHandler}
    >
      <Sheets
        className={generateHtmlClass({ unstyled })}
        alignment="right"
        size={size}
        header={headerNode}
        footer={
          footer ? <ModalFooter renderBorder>{footer}</ModalFooter> : null
        }
        fullSize
      >
        {unstyled ? children : <ModalBody>{children}</ModalBody>}
      </Sheets>
    </Modal>
  );
};

export function ModalStack({ children }: { children?: ReactNode }) {
  const [modalStack, setModalStack] = useState<ModalProps[]>([]);
  const [toast, setToast] = useState<string>();
  const [toastExpired, setToastExpired] = useState(false);
  const [prompt, setPrompt] = useState<false | PromptProps>(false);

  const value = useMemo<ModalStackValue>(() => {
    function openModal<T>(
      type: ModalProps["type"],
      component: FC<T>,
      args?: (T & ModalOptions) | string,
    ) {
      if (typeof args === "string") {
        args = { header: args } as T & ModalOptions;
      }

      // destructure args into component props and known modal options
      const { onClose, onBack, size, unstyled, header, footer, ...props } =
        args ?? {};
      const options = { onClose, onBack, size, unstyled, header, footer };
      const newEntry: ModalProps = {
        props,
        options,
        component,
        visible: true,
        type,
      };

      setModalStack((prev) => {
        // first modal in stack?
        if (!prev.length) {
          return [newEntry];
        }

        // defer adding new modal
        setTimeout(
          () => setModalStack((prev) => [...prev, newEntry]),
          TRANSITION_TIME,
        );

        // hide current modals in stack
        return [...prev.map((entry) => ({ ...entry, visible: false }))];
      });
    }

    function closeAllModals() {
      setModalStack((prev) => {
        // defer stack reset
        setTimeout(() => setModalStack([]), TRANSITION_TIME);

        // hide current modals in stack
        return [...prev.map((entry) => ({ ...entry, visible: false }))];
      });
    }

    return {
      modalStack,
      openSheet: (component, args) => openModal(SheetModal, component, args),
      openTheatre: (component, args) =>
        openModal(TheatreModal, component, args),
      closeModal: (amount = 1) => {
        setModalStack((prev) => {
          // defer stack pop
          setTimeout(() => {
            setModalStack((prev) => {
              const newStack = [...prev].slice(0, prev.length - amount);

              // make last one visible again
              if (newStack.length) {
                newStack.at(-1)!.visible = true;
              }

              return newStack;
            });
          }, TRANSITION_TIME);

          // hide all modals in stack
          return [...prev.map((entry) => ({ ...entry, visible: false }))];
        });
      },

      closeAllModals,
      toast,
      setToast,

      prompt,
      setPrompt: (newPrompt: PromptProps | string | false) => {
        const next =
          typeof newPrompt === "string" ? { title: newPrompt } : newPrompt;
        clearTimeout(promptDismissTimeout);
        setPrompt((prev) => {
          if (!prev && !next) {
            return false;
          }

          if (!prev) {
            return next;
          }

          // defer showing new prompt
          promptDismissTimeout = setTimeout(
            () => setPrompt(next),
            TRANSITION_TIME,
          );

          // hide current prompt
          return { ...prev, visible: false };
        });
      },
    };
  }, [modalStack, toast, prompt]);

  // toast changed => start expiration timer
  useEffect(() => {
    if (!toast) {
      return;
    }

    const timeout = Math.max(Math.min(toast.length * 50, 10000), 5000);
    const timer = setTimeout(() => setToastExpired(true), timeout);

    return () => {
      clearTimeout(timer);
      setToastExpired(false);
    };
  }, [toast]);

  // active toast expired => clear toast
  useEffect(() => {
    if (!toastExpired) {
      return;
    }

    const timer = setTimeout(() => setToast(undefined), TRANSITION_TIME);
    return () => {
      clearTimeout(timer);
      setToast(undefined);
    };
  }, [toastExpired]);

  return (
    <ModalStackContext.Provider value={value}>
      {children}

      {value.modalStack.map((modal, index) => (
        <modal.type key={index} {...modal.options} visible={modal.visible}>
          <modal.component {...modal.props} />
        </modal.type>
      ))}

      {value.toast && (
        <Toast
          text={value.toast}
          isOpen={!toastExpired}
          onCloseRequest={() => setToastExpired(true)}
          className="skapa-focus-portal"
        />
      )}

      {value.prompt && <PromptModal {...value.prompt} />}
    </ModalStackContext.Provider>
  );
}

export function useModals() {
  return useContext(ModalStackContext);
}
