import { useQuery } from "@tanstack/react-query";

import { UserRolePermissions } from "@pdpp/lib-planta";

import { plantaApi } from "../../api/fetch";
import { userPermissions$ } from "./state";
import { OPP_AUTHZ } from "../../utils/runtimeConfig";

export const useGetUserPermissions = (): {
  data: UserRolePermissions | null;
  refetch: () => void;
  isLoading: boolean;
  isError: boolean;
} => {
  const { data, refetch, isLoading, isError } =
    useQuery<UserRolePermissions | null>({
      enabled: OPP_AUTHZ,
      initialData: null,
      queryKey: [`opp-user-permissions`],
      retry: false,
      queryFn: async () => {
        const res =
          await plantaApi.jsonInJsonOut<UserRolePermissions>(
            `/users/permissions`,
          );
        userPermissions$.value = res;
        return res;
      },
    });

  return {
    data,
    refetch,
    isLoading,
    isError,
  };
};
