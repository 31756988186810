import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getISOWeek } from "date-fns";

import { plantaApi } from "../../api/fetch";
import type { Budget, BudgetArgs, UseGetBudgetReturn } from "./types";
import { periodBudget$ } from "../period";
import { fmtHhMm } from "../dates/transforms";
import { ORG_NODE_BY_ID, orgNodes$ } from "../org/state";
import { getCostCentresFromOrgNode } from "../org/transforms";
import { ClientBusinessNode } from "../org/types";
import { showV6 } from "../experimental/transforms";
import { budget$, budgetData$ } from "../plan/state";

export const useGetBudget = ({
  unitType,
  publicUnitId,
  orgId,
  startDate,
  endDate,
  keepPreviousData,
}: BudgetArgs): UseGetBudgetReturn => {
  const selectedOrg = ORG_NODE_BY_ID.get(orgId) as ClientBusinessNode;
  const orgNodes = orgNodes$.value;

  const { data, refetch, isLoading } = useQuery<ReadonlyArray<Budget>>({
    queryKey: [`${unitType}-${publicUnitId}-${startDate}-${endDate}`],
    queryFn: async ({ signal }) => {
      if (keepPreviousData === true) {
        return [];
      }
      const budget = await plantaApi.jsonInJsonOut<ReadonlyArray<Budget>>(
        `/budgets/${unitType}/${publicUnitId}?startDate=${startDate}&endDate=${endDate}`,
        { method: "GET", signal },
      );
      return budget;
    },
    enabled: showV6,
  });

  useEffect(() => {
    if (data) {
      budgetData$.value = data;
    }
  }, [data]);

  if (data === undefined) {
    return {
      data: [],
      rawData: [],
      isLoading: false,
      refetch,
    };
  }

  //Get all CCs in the selected org
  const costCentres = getCostCentresFromOrgNode(orgNodes, selectedOrg);

  /* 
  The CostCentre Ids we get from budgeting and extIds for CostCentres are formatted like this: CR71013340 
  Our org endpoint returns it in this format: "CC4030",
  so we have to extract the last 4 digits from the budgeting CCs and compare it to the org CCs.
   */
  const { totalMinutes, filteredData } = data.reduce(
    (acc, item) => {
      if (
        item.costCentreCode.length > 4 &&
        costCentres.has(`CC${item.costCentreCode.substring(6, 10)}`)
      ) {
        acc.filteredData.push(item);
        acc.totalMinutes += item.hours * 60;
      }

      return acc;
    },
    { totalMinutes: 0, filteredData: [] as Budget[] },
  );

  const formattedTotal = fmtHhMm(totalMinutes);
  periodBudget$.value = formattedTotal.toLocaleString();

  /**
   * Aggregates budget hours by week number.
   * If multiple budget entries exist for the same week (e.g., from different cost centers),
   * their hours are summed up into a single entry for that week.
   */
  const processedData = Array.from(
    filteredData.reduce((acc, budget) => {
      const weekNumber = getISOWeek(new Date(budget.date));
      acc.set(weekNumber, (acc.get(weekNumber) ?? 0) + budget.hours);
      return acc;
    }, new Map<number, number>()),
    ([weekNumber, hours]) => ({ weekNumber, hours }),
  );

  budget$.value = processedData;

  return {
    data: processedData,
    rawData: filteredData,
    isLoading,
    refetch,
  };
};
