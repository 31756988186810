/**
 * Temporary hardcoded types until they're available in the API box
 * https://github.com/ingka-group-digital/pdpp-drivers-monorepo/blob/main/x-adr/0006-planta-api-strategy/README.md?plain=1#L69
 */

import { TransactionType } from "@pdpp/planta-server/dist/api/features/headcount/file/bq.types.mjs";

import { type Budget } from "../features/budget/types";

export interface HeadCount {
  /**
   * Id of OrganisationNode
   */
  readonly id: string;
  readonly name: string;
  /**
   * Contains values of headcount for each hour quarter of a day
   * Values of headcount for one day are sorted in ascending order (96 quarters from 00:00 to 23:45)
   * Array contains values for all queried days sorted in ascending order (96 values for each day)
   */
  readonly dayQuarters: ReadonlyArray<number>;
  /**
   * Contains Label and Type of Headcount for each queried day, returned only when `id`
   * represents OrgActivity and for `DAY` and `DAY_QUARTERS` views
   */
  readonly sourceType?: ReadonlyArray<TransactionType>;
  readonly sourceLabel?: ReadonlyArray<string | null>;
  readonly sourceId?: ReadonlyArray<string | null>;
  readonly budgetDayQuarters?: ReadonlyArray<number>;
  readonly budgets?: ReadonlyArray<Budget>;
}

export interface PlanViewData {
  readonly data: ReadonlyArray<HeadCount>;
}

export enum PlanView {
  /** `Period` button */
  Week = "week",
  /** `Week` button */
  Day = "day",
  /** `Day` button */
  DayQuarters = "dayQuarters",
}

export interface GetPlanViewDataArgs {
  readonly view: PlanView;
  readonly orgNodeId: string;
  /**
   * Order of Date objects represent start and end of selected period
   * */
  readonly period: Readonly<[string, string]>;
}

export interface PublishedChange {
  total: string;
  data: PublishedChangeItem[];
}

export interface PublishedChangeItem {
  readonly activityId: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly createdOn: string;
}
