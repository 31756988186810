type Id<X extends string = string> = `/${X}`;
type Plan = "/plan";
type Methods = "/methods";
type Templates = "/templates";
type Calendar = "/calendar";
type Edit = "/edit";
type Assign = "/assign";

function method(methodId: string): `${Plan}${Methods}${Id}` {
  return `/plan/methods/${methodId}`;
}

function methodEdit(methodId: string): `${Plan}${Methods}${Id}${Edit}` {
  return `/plan/methods/${methodId}/edit`;
}

function methodTemplateList(
  methodId: string,
): `${Plan}${Methods}${Id}${Templates}` {
  return `/plan/methods/${methodId}/templates`;
}

function methodTemplate(
  methodId: string,
  templateId: string,
): `${Plan}${Methods}${Id}${Templates}${Id}` {
  return `/plan/methods/${methodId}/templates/${templateId}`;
}

function methodAssign(
  methodId: string,
): `${Plan}${Methods}${Id}${Calendar}${Assign}` {
  return `/plan/methods/${methodId}/calendar/assign`;
}

function methodTemplateEdit(
  methodId: string,
  templateId: string,
): `${Plan}${Methods}${Id}${Templates}${Id}${Edit}` {
  return `/plan/methods/${methodId}/templates/${templateId}/edit`;
}

function methodCalendarList(
  methodId: string,
): `${Plan}${Methods}${Id}${Calendar}` {
  return `/plan/methods/${methodId}/calendar`;
}

export const ROUTES = {
  PUBLISH: {
    path: `/publish`,
    part: "publish",
  },
  COUNTRYACTIVITIES: {
    path: `/countryActivities`,
    part: "countryActivities/*",
  },
  PLAN: {
    all: `plan/*`,
    path: `/plan`,
    OVERVIEW: {
      path: `/plan/overview`,
      part: "overview",
    },
    PLAN: {
      path: `/plan/plan`,
      part: "plan",
    },
    ACTIVITIES: {
      path: `/plan/activities`,
      part: "activities/*",
      NEW: {
        part: "new",
        path: `/plan/activities/new`,
      },
    },

    METHODS: {
      part: "methods",
      path: `/plan/methods`,
      id: method,
      new: () => method("new"),
      edit: methodEdit,
      newPart: "new",
      editPart: "edit",
      TEMPLATES: {
        part: "templates",
        partAll: "/templates/*",
        path: methodTemplateList,
        id: methodTemplate,
        new: (methodId: string) => methodTemplate(methodId, "new"),
        edit: methodTemplateEdit,
        newPart: "new",
        editPart: "edit",
      },
      CALENDAR: {
        part: "calendar/*",
        path: methodCalendarList,
        assign: (methodId: string) => methodAssign(methodId),
        assignPart: "assign",
      },
    },
  },
} as const;
