import { signal, effect } from "@preact-signals/safe-react";

import {
  getShareableSetting,
  setShareableSetting,
} from "../../utils/shareableSetting";
import type { ClientBusinessNode } from "./types";
import { getAncestors } from "./transforms";
import { View } from "../../types/client";

const KEY = "orgNode";

const storageView = localStorage.getItem("view") as View;

export const orgId$ = signal(getShareableSetting(KEY) ?? undefined);
export const orgNodes$ = signal<ReadonlyArray<ClientBusinessNode>>([]);
export const selectedUnit$ = signal<ClientBusinessNode | undefined>(undefined);
export const countryCode$ = signal<string | undefined>("SE");
export const selectedForecastView$ = signal<View | undefined>(
  storageView ?? View.Period,
);

type OrgNodeById = Map<string, ClientBusinessNode>;

export const ORG_NODE_BY_ID: OrgNodeById = new Map();

effect(() => {
  const value = orgId$.value;
  if (value !== undefined) {
    setShareableSetting(KEY, value);
  }
});

// Populates orgNodes Map
effect(() => {
  const orgNodes = orgNodes$.value;

  for (const node of orgNodes) {
    ORG_NODE_BY_ID.set(node.id, node);
  }
});

effect(() => {
  const orgNodes = orgNodes$.value;
  const orgIg = orgId$.value;

  const ancestors = getAncestors(orgNodes, orgIg);
  const selectedUnit = ancestors.find((ancestor) => ancestor.type === "unit");

  if (selectedUnit) {
    selectedUnit$.value = selectedUnit;
    countryCode$.value = selectedUnit.parentId;
  }
});

effect(() => {
  if (selectedForecastView$.value !== undefined) {
    localStorage.setItem("view", selectedForecastView$.value ?? "");
  }
});
